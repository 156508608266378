<template>
  <span v-if="dose.prescribedDose" data-testid="insulin-dose-prescribed">
    {{ dose.prescribedDose }} {{ dose.dosingDisplayUnit }}
  </span>
  <div v-else class="background fill-height" />
</template>

<script>
export default {
  name: 'InsulinDiaryPrescribedDose',
  props: { dose: { type: Object, required: true } },
}
</script>

<style lang="scss" scoped>
.background {
  background-color: $nn-TB_T98;
}
</style>
